import _set from "./set";
var exports = {};
var set = _set;

exports = function update(key, value) {
  return function (o) {
    return set({
      key: key,
      value: value,
      type: "update"
    })(o);
  };
};

export default exports;